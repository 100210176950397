import {JwtStringProfile} from "./g-profile";
import {SHA2_512} from '../../../utils/sha2';
import {
  AuthStore,
  checkNetwork,
  DataStorages,
  Debug,
  EliteConfig,
  extractJwtUnsafe,
  fetchAndReturnCors,
  JwtProducer,
  RealTimeManager, showError,
  UsersProfileStore
} from "../../../utils/utils";

export function testLogin() {
  alert("testLogin");
}

function hexPairToBase64(n1: number, n2: number) {
  return n1 * 16 + n2;
}

function toNumber(ch): number {
  switch (ch) {
    case '0':
      return 0;
    case '1':
      return 1;
    case '2':
      return 2;
    case '3':
      return 3;
    case '4':
      return 4;
    case '5':
      return 5;
    case '6':
      return 6;
    case '7':
      return 7;
    case '8':
      return 8;
    case '9':
      return 9;
    case 'a':
      return 10;
    case 'b':
      return 11;
    case 'c':
      return 12;
    case 'd':
      return 13;
    case 'e':
      return 14;
    case 'f':
      return 15;
    default:
      return 0;
  }
}

function hexToBase64(text: string) {
  var uint8 = new Uint8Array(text.length / 2)

  for (let i = 0; i < text.length; i += 2) {
    let ch1 = text.charAt(i);
    let ch2 = text.charAt(i + 1);
    let n1 = toNumber(ch1);
    let n2 = toNumber(ch2);

    uint8[i / 2] = hexPairToBase64(n1, n2);
    //alert(ch1 + " " + ch2 + " ==> " + n1 + " " + n2 + " ==> " + hexPairToBase64(n1, n2));
  }

  return btoa(String.fromCharCode.apply(null, uint8))
}

export function testHash(text) {
  return hexToBase64(SHA2_512(text));
}

function hashOnce(text) {
  return hexToBase64(SHA2_512(text));
  //var shaObj = new SHA2("SHA-512", "TEXT");

  //shaObj.update(text);

  //return shaObj.getHash("B64");
}

function hashIterative(password, salt, iterations) {
  let hash = hashOnce(btoa(salt) + '$' + password);

  for (let i = 1; i < iterations; i++) {
    hash = hashOnce(hash);
  }

  return hash;
}

export function multiSha(password, user) {
  return hashIterative(password, user, 100);
}

export function loginRetrieveProfile(targetNetworkId: number, email: string, pwHash: string, jwtIds = true, jwtFull = true): Promise<JwtStringProfile> {
  AuthStore.log("loginRetrieveProfile" + targetNetworkId)
  let url = populateHttpLoginParams(EliteConfig.urlGProfile + '/loginRetrieveProfile', targetNetworkId, email, pwHash, null, null, jwtIds, jwtFull);

  return fetchAndReturnCors(url, true, "GET").then(res => res.result);
}

export function completeUserCreation(targetNetworkId: number, jwt: string, firstName: string, lastName: string): Promise<JwtStringProfile> {
  AuthStore.log("completeUserCreation" + targetNetworkId)
  let url = populateHttpLoginParams(EliteConfig.urlGProfile + '/completeUserCreation', targetNetworkId, null, null, firstName, lastName, true, true);

  url.searchParams.append("jwtGlobal", jwt); // jwt valid in global network, not in the current network

  return fetchAndReturnCors(url, true, "PUT")
}

export function resendValidationEmail(targetNetworkId: number, email: string, firstName: string, lastName: string): Promise<string> {
  AuthStore.log("resendValidationEmail" + targetNetworkId)
  let url = populateHttpLoginParams(EliteConfig.urlGProfile + '/resendValidationEmail', targetNetworkId, email, null, firstName, lastName, false, false);

  return fetchAndReturnCors(url, true, "POST")
}


export function createUserRequest(targetNetworkId: number, email: string, firstName: string, lastName: string, pwHash: string, jwtIds = true, jwtFull = true): Promise<JwtStringProfile> {
  AuthStore.log("createUserRequest" + targetNetworkId)
  let url = populateHttpLoginParams(EliteConfig.urlGProfile + "/createUserRequest", targetNetworkId, email, pwHash, firstName, lastName, jwtIds, jwtFull);

  return fetchAndReturnCors(url, true, "POST")
}

export function requestPasswordReset(email: string) {
  AuthStore.log("requestPasswordReset")
  let url = new URL(EliteConfig.urlGProfile + '/requestPasswordReset');

  url.searchParams.append('email', email);

  fetchAndReturnCors(url, true, "GET")
}


function populateHttpLoginParams(urlEndpoint: string, targetNetworkId: number, email: string, pwHash: string, firstName: string, lastName: string, jwtIds: boolean, jwtFull: boolean) {
  let url = new URL(urlEndpoint);

  url.searchParams.append('targetNetworkId', '' + checkNetwork(targetNetworkId));

  if (firstName)
    url.searchParams.append('firstName', firstName);
  if (lastName)
    url.searchParams.append('lastName', lastName);

  url.searchParams.append('email', email);
  url.searchParams.append('pwHash', pwHash);
  url.searchParams.append('jwtIds', '' + jwtIds);
  url.searchParams.append('jwtFull', '' + jwtFull);


  return url;
}

export function extractCurrentJwtUnsafe() {
  let rememberMeEmail = DataStorages.auto().getItem('login-email')

  if (!rememberMeEmail)
    return null;

  let jwt: JwtProducer = AuthStore.jwtFromLocalStorage(rememberMeEmail)

  return jwt ? extractJwtUnsafe(jwt.extract()[0]) : null
}

export function extractCurrentProfileUnsafe(): string {
  let rememberMeEmail = DataStorages.auto().getItem('login-email')

  if (!rememberMeEmail)
    return null;

  return AuthStore.profileFromLocalStorage(rememberMeEmail)
}

function isJwtExpired(jwt) {
  return jwt.exp < (Date.now() / 1000)
}

export enum LoginError {
  RefreshTokenExpired = "__REFRESH_TOKEN_EXPIRED__",
  LoginMailUnknown = "__MAIL_UNKNOWN__",
  LoginRequired = "__LOGIN_REQUIRED__",
}

export function attemptLoginThen(then: (success, message) => void) {
  AuthStore.log("attemptLoginThen")
  autoLogin(_ => then(true, null), err => {
    if (err && err != LoginError.LoginMailUnknown && err != LoginError.LoginRequired && err != LoginError.RefreshTokenExpired)
      showError("Login error: " + err)
    then(false, err)
  }, false)
}

/** Tries to log in using the data in the localStorage */
export function autoLogin(onSuccess, onError, retrieveRole = true) {
  AuthStore.log("attemptLoginThen...")
  let rememberMeEmail = DataStorages.auto().getItem('login-email')
  let jwt: JwtProducer = null

  if (rememberMeEmail) {
    Debug.trace("remembering: " + rememberMeEmail.substring(0, rememberMeEmail.indexOf('@')))
    jwt = AuthStore.jwtFromLocalStorage(rememberMeEmail)

    if (!jwt) {
      Debug.trace("No JWT")
      if (onError != null)
        onError(LoginError.LoginRequired)
    }
  } else {
    jwt = AuthStore.jwtFromSessionStorage()
  }

  if (jwt) {
    Debug.trace("jwt retrieved")
    AuthStore.getInstance().setJwtAccess(rememberMeEmail, jwt)

    if (isJwtExpired(extractJwtUnsafe(jwt.getRefreshToken())) &&
      isJwtExpired(extractJwtUnsafe(jwt.getAccessToken()))) {
      Debug.trace("jwt expired, ask for refresh")
      if (onError != null)
        onError(LoginError.RefreshTokenExpired)
      return
    }

    jwt.asyncGet("autoLogin()", jwtAccess => {
      Debug.trace("jwt access retrieved")
      if (retrieveRole) {
        UsersProfileStore.getInstance().loadMyLastProfileRole(role => {
          Debug.trace("retrieving role")
          if (role) {
            Debug.trace("role retrieved")
            //console.log("AutoLogin jwt: " + role.jwtRole)
            AuthStore.getInstance().refreshJwtAccessOnly(role.jwtRole)
            AuthStore.getInstance().setLicenseParams(role.effectiveParams)
          }

          RealTimeManager.getInstance().setNewJwt(jwtAccess)

          if (onSuccess)
            onSuccess(jwtAccess, role ? role.publicRole : null)
        })
      } else
      if (onSuccess)
        onSuccess(jwtAccess, null)
    }, onError)
  } else {
    Debug.trace("Mail unknown: " + rememberMeEmail)
    if (onError != null)
      onError(LoginError.LoginMailUnknown)
  }
}

